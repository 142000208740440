/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { CookieConsentProvider } from "./src/context/ConsentToCookiesContext"
import 'normalize.css/normalize.css';


export const wrapRootElement = ({ element }) => (
    <CookieConsentProvider>{element}</CookieConsentProvider>
)
